<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4 pv3">
        <div class="tc">
          <div class="ba b--light-gray bw2">
            <h1 class="f3 f2-ns vidaloka tc ttu vidaloka">
              {{ $t("faq.title") }}
            </h1>
            <p class="tc i roboto sans-serif piiia-grey">
              {{ $t("faq.subtitle") }}
            </p>
          </div>
        </div>

        <article class="karla tracked-light lh-copy">
          <p
            v-html="$t('faq.paragraph')"
            v-if="$t('faq.paragraph').length > 0"
          ></p>

          <article
            v-for="(translated, index) in $t('faq.article')"
            v-bind:key="index"
          >
            <div
              v-for="(paragraph, index) in translated.paragraphs"
              v-bind:key="index"
            >
              <h2>{{ paragraph.title }}</h2>
              <p
                v-html="nested_translated"
                v-for="(nested_translated,
                nested_index) in paragraph.nested_paragraphs"
                v-bind:key="nested_index"
              ></p>
            </div>
          </article>
        </article>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: "faq-view"
};
</script>
